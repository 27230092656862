@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;700&display=swap');

body,
button,
input,
select,
optgroup,
textarea {
	color: black;
	font-family: Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
	font-size: 1rem;
	line-height: 1.5;
}
html,
body {
	height: 100%;
	margin: 0;
	padding: 0;
	max-width: 100%;
	overflow-x: hidden;
}
h1, h2 {
	font-weight: 300;
}
h3, h4 {
	font-weight: bold;
}
h1 {
	font-size: 3.75rem;
}
h2 {
	font-size: 1.875rem;
	font-weight: 300;
}
h3 {
	font-size: 1.25rem;
}
.modal {
	display: block;
	background: hsl(0 0% 0% / 50%);
}
#main-wrapper {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}
main {
	flex: 1;
}
.fontsize {
	font-size: 150% !important;
	line-height: 1.6;
}
.btn, .modal-title, .form-select, #fileForm {
	font-size: 1em;
}
.form-select {
	display: inline;
	width: auto;
	margin-left: 10px;
}
.btn-primary {
	background-color: #425ba6;
}
.btn-primary:hover {
	background-color: #1B3067;
}
.fontsize input[type='checkbox'] {
	height: 2em;
	width: 2em;
	margin-right: 1em;
}
.page-loading div {
	margin: 100px;
	width: 100px;
	height: 100px;
}

/* Landing Page
--------------------------------------------- */
.landing-page-about img {
	width: 100%;
}
.landing-page-about h3 {
	margin-bottom: 25px;
}
.landing-page-about div {
	margin: 43px 0;
}
.landing-page {
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding-bottom: 30px;
}
.landing-page-cards {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	margin: 60px 0 30px;
}
.landing-page-cards div {
	max-width: 100%;
	background-color: #DEE6F1;
	border-radius: 20px;
	padding: 25px;
	margin-bottom: 30px;
}
.landing-page-cards h4 {
	font-size: 18px;
	font-weight: bold;
	margin-bottom: 1rem;
}
.landing-page-cards p {
	font-size: 14px;
}
.landing-page-working {
	width: 100%;
	display: flex;
	flex-direction: column;
}
.landing-page-working > * {
	align-self:center;
}
.landing-page-working h5 {
	font-size: 15px;
}
.landing-page-working a {
	text-decoration: none;
	color:#425BA6;
	margin-bottom: 50px;
}
.landing-page-working button {
	padding: 10px 40px;
	border-radius: 30px;
	text-transform: uppercase;
}

/* Contrast mode
--------------------------------------------- */
.contrast * :not(.modal ) {
	background-color: black !important;
	color: white !important;
}
.contrast .modal {
	background: rgb(255, 255, 255, 0.3)
}
.contrast tr,
.contrast .site-header {
	border-bottom: 1px solid white;
}
.contrast .accessibility-icon:hover {
	border: 2px solid white;
}

/* Header
--------------------------------------------- */
.site-header {
	background-color: white;
	position: sticky;
	top: 0;
	display: flex;
	z-index: 666;
	width: 100%;
	padding-bottom: 1rem;
	box-shadow: 0px 1px 4px rgba(0,0,0,0.2);
}
.header_ue {
	position: absolute;
	left: calc(50% - 30px);
	top: 10px;
}
.navbar-brand {
	flex: 1;
	position: relative;
}
.navbar-brand img {
	vertical-align: baseline !important;
}
.nav-wrapper {
	display: flex;
	flex-direction: column;
	flex: 0;
	padding: 0 20px;
}

@media (max-width: 768px) {
	.navbar-brand img {
		margin-top: 10px;
		width: 33px;
		height: 33px;
		object-fit: cover;
		object-position: 0 0;
	}
}
@media screen and (min-width: 992px) {
	.site-header button {
		text-decoration: none;
		display: inline-block;
		font-weight: 400;
	}
	.navbar-brand {
		order: 1;
	}
	.navbar-collapse {
		order: 2;
	}
	.accessibility-toolbar {
		order: 3;
	}
	.header_ue {
		order: 4;
		position: initial;
	}
	.landing-page-about {
		display: grid;
		grid-template-columns: repeat(2, minmax(0, 1fr));
		margin: 60px 0;
	}
	.landing-page-title {
		display: flex;
		flex-direction:column;
		justify-content: center;
	}
	.landing-page-about div {
		margin: 0;
		padding-right: 30px;
	}
	.landing-page-about img {
		grid-column: 2;
		grid-row: 1 / 3;
	}
	.landing-page-cards {
		flex-direction: row;
	}
	.landing-page-cards div {
		max-width: 230px;
	}
	.landing-page-cards h4 {
		height: 3em;
		line-height: 1.5em;
	}
}
.site-header button {
	color: #30385C;
	font-size: 1.2em;
	margin: 5px 5px 0;
	border: none;
	background: none;
}
.site-header button:hover {
	font-weight: 700;
}
.accessibility-toolbar {
	align-items: center;
}
.accessibility-icon {
	height: 2em;
	width: 2em;
	background: none;
	border: none;
}
.accessibility-icon:hover  {
	border: 2px solid black;
}

/* Table
--------------------------------------------- */
.table .btn {
	margin: 1%;
}
.table-header {
	display: flex;
	justify-content: space-between;
	margin-top: 2rem;
}
.table-header h1 {
	font-weight: 100;
	color: #30385C;
}
.table-header button {
	text-transform: uppercase;
	font-weight: 300;
}

@media(max-width: 768px) {
	.table-header {
		display: block;
	}
	.table-header > button {
		margin-top: 1rem;
		width: 100%;
	}
	.table {
		margin-top: 1rem;
	}
	.table button {
		width: 50%;
	}
	.table td {
		display: flex;
		justify-content: space-between;
		border-bottom: 1px solid lightgray;
		width: 100%;
	}
	.table tr > td:nth-child(n+3)::before {
		content: attr(data-label);
		float: left;
		font-weight: bold;
		text-transform: uppercase;
	}
	.table > thead, .hidden-row {
		display: none !important;
	}
}
.reload_btn {
	height: 2.5rem;
	width: 2.5rem;
	margin-left: 1rem;
	padding: 0;
}
.reload_btn .spinner-border {
	font-size: 13px;
	margin-top: 6px;
	height: 1.2rem;
	width: 1.2rem;
}

/* Footer
--------------------------------------------- */
.site-footer {
	height: 5em;
	background-color: #1B3067;
	color: white;
	font-weight: 400;
}
.site-info {
	max-width: 1280px;
	margin: auto;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 100%;
	padding-left: 14px;
	padding-right: 14px;
	flex-wrap: wrap;
} 
.site-info > * {
	flex-shrink: 0;
}
#footer-navigation ul {
	margin: 0;
	padding: 0;
	list-style: none;
	font-size: 0.8em;
}
#footer-navigation a:hover,
.pcss-wrapper:hover span {
	text-decoration: underline;
}

@media all and (max-width: 940px) {
	.site-footer {
		height: auto;
		min-height: 5rem;
		padding-top: 1rem;
		padding-bottom: 1rem;
	}
	.social-footer {
		order: 2;
		padding-left: 1rem;
		padding-right: 1rem;
	}
	#footer-navigation { order: 3; }
	.pcss-wrapper { order: 4; }
	.dariah-footer-logo { order: 1; }
}
@media all and (max-width: 439px) {
	.pcss-wrapper { padding-top: 1rem; }
	.social-footer {
		padding-left: 2rem;
		padding-right: 0;
	}
	#footer-navigation { padding-top: 1rem; }
}
.pcss-info {
	font-size: 0.8em;
	display: flex;
	align-items: center;
	justify-content: center;
}
.pcss-info img {
	height: 33px;
	width: 90px;
	margin-right: 10px;
}
@media screen and (min-width: 1285px) {
	.site-info  {
	  padding-left: 0px;
	  padding-right: 0px;
	}
}
.site-info a {
	color: white;
	text-decoration: none;
}

/* Founds
--------------------------------------------- */
.fund {
	display: flex;
	width: 100%;
	justify-content: space-around;
	flex-wrap: wrap;
	align-items: center;
	max-width: 1280px;
	margin: 10em auto 1em;
	padding: 0 14px;
}

@media screen and (min-width: 1285px) {
	.fund {
		padding: 0;
	}
}

.fund img {
	margin-bottom: 1.4em;
	max-width: 200px;
}

/* Socials
--------------------------------------------- */
.ib::before {
	display: inline-block;
	height: 1em;
	width: 1em;
	background: currentColor;
	vertical-align: -.125em;
	mask-image: var(--url);
	mask-repeat: no-repeat;
	mask-position: center;
	-webkit-mask-image: var(--url);
	-webkit-mask-repeat: no-repeat;
	-webkit-mask-position: center;
	content: '';
}
/* Icon: fa-facebook
 * License: Open Font License
 * Html: <i class="ib ib-fa-facebook"></i>
 */
.ib-fa-facebook {
	--url: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 896 1664"%3e%3cpath d="M895 12v264H738q-86 0-116 36t-30 108v189h293l-39 296H592v759H286V905H31V609h255V391q0-186 104-288.5T667 0q147 0 228 12z" fill="currentColor"/%3e%3c/svg%3e');
}
/* Icon: mdi-youtube
 * License: Open Font License
 * Html: <i class="ib ib-mdi-youtube"></i>
 */
.ib-whh-youtube {
	--url: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 768"%3e%3cpath d="M928 736q-222 32-416 32q-86 0-190-8t-165-16l-61-8q-27-5-47.5-37.5t-30-78.5t-14-86T0 461V307Q0 52 96 32Q318 0 512 0q86 0 190 8t165 16l61 8q29 4 49.5 36.5T1007 148t13 86t4 73v154q0 36-3 73t-12 85t-30 80t-51 37zM693 359L431 199q-11-10-29-5.5T384 208v352q0 11 18 15t29-6l262-160q11-10 11-25t-11-25z" fill="currentColor"/%3e%3c/svg%3e');
}
.social {
	position: fixed;
	top: calc(100vh /2 );
	left: 0px;
	display: flex;
	flex-direction: column;
	transform: translateY(-50% );
	z-index: 500;
}
.social a {
	width: 40px;
	height: 40px;
	background-color: #30385C;
	margin-bottom: 1px;
	transition: all 0.2s ease;
	color: white;
	display: flex;
	justify-content: center;
	align-items: center;
}	
.social a:hover { background-color: #91a2cd; }
.social-footer { display: flex; }
.site-info .social-footer a {
	margin-left: 3px;
	height: 30px;
	width: 30px;
	background-color: white;
	color: #30385C;
	display: flex;
	justify-content: center;
	align-items: center;
}

/* Pagination
--------------------------------------------- */

.pagination {
	width: 100%;
	display: block;
	justify-content: space-between;
	align-items: center;
}

.pagination .container {
	width: 100%;
	flex: auto;
	display: flex;
	justify-content: center;
	align-items: center;
}
.pagination .current_page {
	color: white;
	font-weight: 700;
	background-color: #425ba6 !important;
}
.contrast .pagination .current_page {
	color: black !important;
	background-color: white !important;
}
.pagination button {
	font-size: 1em;
	margin: 5px;
	width: 4em;
	height: 4em;
	border-radius: 5px;
	background: white !important;
	outline: none !important;
}
.pagination .container .arrows:disabled {
	filter: opacity(0.5);
}
.pagination .deliminator,
.pagination .arrows {
	border: none;
}
.pagination .item-count {
	text-align: end;
	margin: 20px 10px;
}
@media (max-width: 768px) {
	.xs-hidden,
	.deliminator {
		display: none;
	}
}
.contrast svg path {
	filter: brightness(0) invert(1);
}